

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Material Kit 2 React examples
import MKTypography from "components/MKTypography";

import { Avatar, Icon } from "@mui/material";
import bundleOfferImg from "assets/images/bundleOffer.png";
import celebrate from "assets/images/celebrate.png";
import arrowDown from "assets/images/arrowDown.png";
import WebsitePageBundle from "pages/Presentation/sections/WebsitePageBundle";
import MKButton from "components/MKButton";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { post } from "BaseUrl/api";
function BundleOffer({ section }) {
  const navigate = useNavigate();
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Icon
        className={className}
        style={{
          ...style,
          display: "block",
          color: "black",
          fontSize: "200px",
        }}
        onClick={onClick}
      >
        arrow_forward
      </Icon>
      // <ArrowForwardIosIcon
      //   className={className}
      //   style={{ ...style, display: 'block', color: 'black' }}
      //   onClick={onClick}
      // />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Icon
        className={className}
        style={{ ...style, display: "block", color: "black" }}
        onClick={onClick}
      >
        arrow_back
      </Icon>
      // <ArrowBackIosIcon
      //   className={className}
      //   style={{ ...style, display: 'block', color: 'black' }}
      //   onClick={onClick}
      // />
    );
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const [headingHero2, setHeadingHero2] = useState("");
  const [descriptionHero2, setDescriptionHero2] = useState("");
  const [headingHero3, setHeadingHero3] = useState("");
  const [descriptionHero3, setDescriptionHero3] = useState("");
  const fetchContent = async () => {
    try {
      const postData = {
        name: "BUNDLE",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("WEBSRITE CONTENT BUNDLE");
      console.log(apiData.data.description);

      // find striing equal to 'Pkr' in the string and 
      let n = apiData.data.description.search("Pkr");
      // now get the string from 0 to n-1 
      let str = apiData.data.description.substring(0, n - 1);
      // now get the end value of the string str 
      let end = str.substring(str.length - 1);
      console.log("END", end);



      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);

      }
    } catch (error) { }
  };
  const fetchContent1 = async () => {
    try {
      const postData = {
        name: "BUNDLE_2",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero2(apiData.data.heading);
        setDescriptionHero2(apiData.data.description);

      }
    } catch (error) { }
  };
  const fetchContent2 = async () => {
    try {
      const postData = {
        name: "BUNDLE_3",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero3(apiData.data.heading);
        setDescriptionHero3(apiData.data.description);

      }
    } catch (error) { }
  };
  useEffect(() => {
    fetchContent();
    fetchContent1();
    fetchContent2();
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    { id: 1, heading: headingHero, text: descriptionHero },
    { id: 2, heading: headingHero2, text: descriptionHero2 },
    { id: 3, heading: headingHero3, text: descriptionHero3 },
    // Add more slides as needed
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };
  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000); // Change slide every 3 seconds

    return () => clearInterval(slideInterval); // Clear interval on component unmount
  }, []);
  return (
    <MKBox
      component="section"
      //  variant="gradient"
      // bgColor="light"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container
        style={{
          //  marginBlock:section? "60px":"0px"
          marginBlock: "60px",
        }}
      >
        {/* <Grid container spacing={3} alignItems="center"> */}
        {/* <Grid item xs={12} lg={12}> */}
        <MKTypography
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
          textAlign="center"
          variant="h2"
          fontWeight="bold"
          color="dark"
        >
          {headingHero}
        </MKTypography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            src={bundleOfferImg}
            alt="bundle"
            style={{ width: "60px", height: "auto" }}
            variant="square"
          />
          <Avatar
            src={celebrate}
            alt="bundle"
            style={{ width: "60px", height: "auto" }}
            variant="square"
          />
        </div>


        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          style={{
            width: "100%",
            display: window.innerWidth > 700 ? "none" : "block"
          }}
        >
          <MKBox p={2}
            style={{
              width: "100%",
              textAlign: "justify",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}

            textAlign="center">
            <h3>{slides[currentSlide].heading}</h3>
            <p
              style={{ fontSize: "15px" }}
            >{slides[currentSlide].text}</p>
            <MKButton
              // variant="gradient"
              onClick={() => {
                navigate('/enroll', {
                  state: {
                    package_name: slides[currentSlide].heading,
                    courseDetails: {
                      package_price: slides[currentSlide].text
                    },
                    isBundle: true
                  }
                });

              }}
              color="primary"
              size="small"
              component="a"
              style={{
                marginBlock: "2%",
                fontSize: window.innerWidth < 1024 ? "15px" : "15px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Avail this Offer For : 2999 PKR
            </MKButton>
          </MKBox>
          <MKBox>
            <Button onClick={prevSlide} startIcon={<ArrowBackIosIcon />}>
              Prev
            </Button>
            <Button onClick={nextSlide} endIcon={<ArrowForwardIosIcon />}>
              Next
            </Button>
          </MKBox>
        </MKBox>

        <div
          className="slider-container"
          style={{
            margin: "50px 0",
            display: window.innerWidth < 700 ? "none" : "block",
          }}
        >
          <Slider {...settings}>
            <div>
              <h2>{headingHero}</h2>
              <p>{descriptionHero}</p>
              <MKButton
                // variant="gradient"
                onClick={() => {
                  navigate('/enroll', {
                    state: {
                      package_name: 'AZAD OFFER', courseDetails: {
                        package_price: 2999,
                      },
                      isBundle: true
                    }
                  });
                }}
                color="primary"
                size="small"
                component="a"
                style={{
                  marginBlock: "2%",
                  fontSize: window.innerWidth < 1024 ? "15px" : "15px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                Avail this Offer For : 2999 PKR
              </MKButton>
            </div>
            <div>
              <h2>{headingHero2}</h2>
              <p>{descriptionHero2}</p>
              <MKButton
                // variant="gradient"
                onClick={() => {
                  navigate('/enroll', {
                    state: {
                      package_name: 'AZAD OFFER', courseDetails: {
                        package_price: 2499,
                      },
                      isBundle: true
                    }
                  });
                }}
                color="primary"
                size="small"
                component="a"
                style={{
                  marginBlock: "2%",
                  fontSize: window.innerWidth < 1024 ? "15px" : "15px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                Avail this Offer For : 2499 PKR
              </MKButton>
            </div>
            <div>
              <h2>{headingHero3}</h2>
              <p>{descriptionHero3}</p>
              <MKButton
                // variant="gradient"
                onClick={() => {
                  navigate('/enroll', {
                    state: {
                      package_name: 'AZAD OFFER', courseDetails: {
                        package_price: 2499,
                      },
                      isBundle: true
                    }
                  });
                }}
                color="primary"
                size="small"
                component="a"
                style={{
                  marginBlock: "2%",
                  fontSize: window.innerWidth < 1024 ? "15px" : "15px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                Avail this Offer For : 2499 PKR
              </MKButton>
            </div>

          </Slider>

        </div>
      </Container>
    </MKBox>
  );
}

export default BundleOffer;
