
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from "react";
import { post } from "BaseUrl/api";
import { get } from "BaseUrl/api";
function FAQS() {
  const [expanded, setExpanded] = useState(null);

  const handleToggle = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };
  const [faqData, setFaqData] = useState([]);

  const fetchFAQs = async () => {
    try {


      const apiData = await get("review/getAllFaqs"); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setFaqData(apiData.result);
      }
    } catch (error) { }
  };
  useEffect(() => {
    fetchFAQs();


  }, []);
  return (
    <MKBox component="section"
      style={{
        backgroundColor: '#f3f3f3',
        paddingBlock: window.innerWidth < 1024 ? '30px' : '50px',
      }}
    >
      <Container>
        <Grid container item>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={10} md={8}>
              <MKTypography variant="h2" align="center" gutterBottom>
                Frequently Asked Questions
              </MKTypography>
              <div>
                {faqData.map((faq, index) => (
                  <div key={index} >
                    <Grid container alignItems="center" style={{ backgroundColor: '#f3f3f3', padding: '10px', border: '1px solid #e7e7e7' }}>
                      <Grid item xs={11}>
                        <MKTypography variant="h5" gutterBottom>
                          {faq.heading}
                        </MKTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => handleToggle(index)}
                          aria-expanded={expanded === index}
                          aria-label="expand"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {expanded === index && (
                      <MKTypography variant="body2" style={{ marginBlock: '10px' }}>
                        {faq.description}
                      </MKTypography>
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FAQS;
