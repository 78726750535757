// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import logo from "assets/images/Logo.png";
import logo1 from "assets/images/EducatLogo.png";
import trainerProfile from "assets/images/ansarTutor.jpeg";

// Data
import colors from "assets/theme/base/colors";

import MKButton from "components/MKButton";
import { useEffect, useState } from "react";
import { get } from "BaseUrl/api";
import { Avatar, CircularProgress } from "@mui/material";
// import { video_url_home } from "BaseUrl/api";
import ReviewSlider from "pages/LandingPages/Author/sections/ReviewsSlider";
import Contact from "pages/LandingPages/Author/sections/Contact";
import Footer from "pages/LandingPages/Author/sections/Footer";
import { post } from "BaseUrl/api";
const { info } = colors;
function DesignBlocks2() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [videoUrl, setVideourl] = useState("");
  const [videoUrl1, setVideourl1] = useState("");
  const [videoUrl2, setVideourl2] = useState("");


  const fetchProducts = async () => {
    try {
      const apiData = await get("package/getAllActivePackages"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        setLoader(false);
      } else {
        console.log(apiData.result);

        console.log("COURSSES");
        let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const fetchUrl = async () => {
    try {
      const apiData = await get("package/getHomepageVideoUrl"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        // setLoader(false);
      } else {
        console.log(apiData.videoUrl);
        const data = apiData.videoUrl;
        let newHeight;

        if (window.innerWidth <= 600) {
          // 600px is generally the breakpoint for mobile devices
          newHeight = `${window.innerWidth}px`;
        } else {
          newHeight = "520px";
        } // Set your desired height here
        const updatedVideoUrl = data.replace(
          'height="100%"',
          `height="${newHeight}"`
        );

        console.log("Video url");
        setVideourl(updatedVideoUrl);
        // let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        // setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const fetchUrl1 = async () => {
    try {
      const apiData = await get("package/getHomepageVideoUrl1"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        // setLoader(false);
      } else {
        console.log(apiData.videoUrl);
        const data = apiData.videoUrl;
        let newHeight;

        if (window.innerWidth <= 600) {
          // 600px is generally the breakpoint for mobile devices
          newHeight = `${window.innerWidth}px`
        } else {
          newHeight = "520px";
        } // Set your desired height here
        const updatedVideoUrl = data.replace(
          'height="100%"',
          `height="${newHeight}"`
        );

        console.log("Video url");
        setVideourl1(updatedVideoUrl);
        // let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        // setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const fetchUrl2 = async () => {
    try {
      const apiData = await get("package/getHomepageVideoUrl2"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        // setLoader(false);
      } else {
        console.log(apiData.videoUrl);


        console.log("Video url asdjhajhdhd");
        setVideourl2(apiData.videoUrl);
        // let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        // setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const [headingHero1, setHeadingHero1] = useState("");
  const [descriptionHero1, setDescriptionHero1] = useState("");
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const [headingHero3, setHeadingHero3] = useState("");
  const [descriptionHero3, setDescriptionHero3] = useState("");
  const [headingHero4, setHeadingHero4] = useState("");
  const [headingHero5, setHeadingHero5] = useState("");

  const [descriptionHero4, setDescriptionHero4] = useState("");
  const fetchContentW3 = async () => {
    try {
      const postData = {
        name: "webinar_page_section_3",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        // setHeadingHero(apiData.data.heading);
        setHeadingHero5(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContent = async () => {
    try {
      const postData = {
        name: "Hero",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentW = async () => {
    try {
      const postData = {
        name: "webinar_page_section_1",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero3(apiData.data.heading);
        setDescriptionHero3(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentW1 = async () => {
    try {
      const postData = {
        name: "webinar_page_section_2",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero4(apiData.data.heading);
        setDescriptionHero4(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentSingleEnroll = async () => {
    try {
      const postData = {
        name: "ENROLLSINGLE",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero1(apiData.data.heading);
        setDescriptionHero1(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  useEffect(() => {
    fetchProducts();
    fetchUrl();
    fetchUrl1();
    fetchUrl2();

    fetchContent();
    fetchContentW();
    fetchContentW1();
    fetchContentW3();
    fetchContentSingleEnroll();
  }, []);
  const largeScreenStyle = {
    fontSize: "80px",
  };

  return (
    <>
      <MKBox component="section" my={1} py={1}>
        <Container>
          {loader ? <CircularProgress size={24} color="primary" /> : null}
          <Grid
            container
            item
            xs={12}
            lg={12}
            flexDirection="column"
            alignItems="center"
            sx={{
              textAlign: "center",
              marginBlock: "20px",
              // my: 6,
              mx: "auto",
              // px: 0.75,
            }}
          >
            {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Infinite combinations"
            container
            sx={{ mb: 2 }}
          /> */}
            <div
              style={{
                position: "fixed",
                top: 5,
                left: 10,
                margin: "16px",
                background: "transparent",
                color: "#fff",
                // padding: '10px 20px',
                // borderRadius: "50%",
                // cursor: "pointer",
                // boxShadow: "-4px 5px 35px 0px rgba(0,0,0,0.75)",
              }}
            >
              <Avatar
                src={logo1}
                variant="square"
                style={{ width: "200px", height: "auto" }}
              />
            </div>
            <div
              style={{
                position: "fixed",
                top: 50,
                right: 10,
                margin: "16px",
                background: "transparent",
                color: "#fff",
                // padding: '10px 20px',
                // borderRadius: "50%",
                // cursor: "pointer",
                // boxShadow: "-4px 5px 35px 0px rgba(0,0,0,0.75)",
              }}
            >
              <Avatar
                src={logo1}
                variant="square"
                style={{
                  width: window.innerWidth < 500 ? "150px" : "200px",
                  height: window.innerWidth < 500 ? "150px" : "200px"
                }}
              />
            </div>
            <div style={{ marginTop: "100px" }}>
              <MKTypography
                style={window.innerWidth >= 1024 ? largeScreenStyle : {}}
                variant="h1"
                fontWeight="bold"
                color="dark"
              >
                {headingHero}
              </MKTypography>
            </div>

            <MKTypography
              variant="h3"
              fontWeight="bolder"
              style={{
                backgroundColor: "black",
                marginBlock: "10px",
                paddingInline: "10px",

                color: "white",
                fontFamily: '"Roboto Condensed", Sans-serif',
              }}
            >
              {" "}The{" "}
              <span style={{ color: "yellow" }}>
                First-Ever Training Center{" "}
              </span>
              for Empowering<span style={{ color: "yellow" }}>Teachers</span>{" "}
              Financially in <span style={{ color: "yellow" }}>Pakistan</span>{" "}
              {/* <span style={{ fontWeight: 500 }}>TSM Academy </span> */}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              fontWeight="light"
              style={{
                width: "80%",
              }}
            >
              {descriptionHero}
            </MKTypography>
            {/* <MKTypography variant="body1" color="dark" fontWeight="light">
              in Pakistan or international students with the highest level of
              profitability. Unlock your potential now with us!
            </MKTypography> */}
            {/* <Grid container justifyContent="center">
            
              <Grid item xs={12} md={8} style={{ marginTop: "3%" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ` ${videoUrl}`,
                  }}
                />
              </Grid>
            </Grid> */}
            <MKButton
              // variant="gradient"
              onClick={() => {
                window.location.href = `/enroll/`;
              }}
              color="primary"
              size="large"
              component="a"
              style={{
                // marginBlock: "2%",
                fontSize: window.innerWidth < 1024 ? "15px" : "30px",
                fontWeight: 500,
                marginTop: "20px",
                textTransform: "capitalize",
              }}
            >
              Start Earning Today!
            </MKButton>

            <div style={{ marginTop: "100px" }}>
              <MKTypography
                style={{ fontSize: "50px" }}
                variant="h1"
                fontWeight="bold"
                color="dark"
              >
                {headingHero3}
              </MKTypography>
            </div>

            <Grid container justifyContent="center">
              {/* <Grid item xs={12} md={2}>
              </Grid> */}
              <Grid item xs={12} md={8} style={{ marginTop: "3%" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ` ${videoUrl1}`,
                  }}
                />
              </Grid>
            </Grid>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: "900px",
                // height: "900px",
                marginTop: "20px",
                borderRadius: "10px",
              }}
              dangerouslySetInnerHTML={{
                __html: `${videoUrl}`,
              }}
            /> */}

            <MKTypography
              style={{
                marginTop: "20px",
              }}
              // style={window.innerWidth >= 1024 ? largeScreenStyle : {}}
              variant="h4"
              fontWeight="medium"
              color="dark"
            >
              Limited seats available !
            </MKTypography>
            <MKTypography
              variant="h4"
              color="dark"
              fontWeight="light"
              style={{
                width: "80%",
              }}
            >
              {descriptionHero3} !!!
            </MKTypography>
            <MKTypography
              variant="h4"
              color="dark"
              fontWeight="light"
              style={{
                width: "80%",
              }}
            >
              Want to know How?
            </MKTypography>
            <MKTypography
              variant="h4"
              color="dark"
              fontWeight="light"
              style={{
                width: "80%",
              }}
            >
              Enroll Yourself for this -{" "}
              <span style={{ fontWeight: 700 }}>Free Webinar Training</span>
            </MKTypography>
            <div>
              {/* <MKTypography
              style={{fontSize:"50px"}}
                variant="h1"
                fontWeight="bold"
                color="dark"
              >
                Free Webinar Training
              </MKTypography> */}
              <MKButton
                // variant="gradient"
                onClick={() => {
                  window.open(videoUrl2, "_blank");
                }}
                color="primary"
                size="large"
                component="a"
                style={{
                  // marginBlock: "2%",
                  fontSize: window.innerWidth < 1024 ? "15px" : "30px",
                  fontWeight: 500,
                  marginTop: "20px",
                  textTransform: "capitalize",
                }}
              >
                Register yourself for Free Webinar
              </MKButton>

              <Grid
                container
                spacing={3}
                alignItems="center"
                style={{ marginBlock: "100px" }}
              >
                <Grid item xs={12} md={12}>
                  <MKTypography
                    style={{
                      marginBlock: "20px",
                      textAlign: "center",
                      marginTop: "50px",
                    }}
                    variant="h1"
                    fontWeight="bold"
                    color="dark"
                  >
                    Trainer's Portfolio/Profile
                  </MKTypography>

                </Grid>
                <Grid item xs={12} lg={6} style={{ marginTop: "40px" }}>
                  <Avatar
                    src={trainerProfile}
                    variant="square"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MKTypography
                    style={{
                      marginBlock: "20px",
                      textAlign: "left",
                      marginTop: "50px",
                    }}
                    variant="h1"
                    fontWeight="medium"
                    color="dark"
                  >
                    Ansar Mahmood
                  </MKTypography>

                  <MKTypography
                    variant="body1"
                    color="dark"
                    fontWeight="light"
                    style={{
                      display: "flex",
                      textAlign: "left",
                      justifyContent: "left",
                      width: "100%",
                    }}
                  >
                    • Online Tutor (Charging 50,000 For One Hour Class)
                    <br />
                    • CEO, TSM Online Tutoring Academy (Over 20 Staff Members)
                    <br />
                    • CEO TSM Tutor Providina Agencv (Tackles 50 Proiects a
                    Month)
                    <br />
                    • Worked With USA-Based Platforms (Paying $18/hr.)
                    <br />
                    • Working On Different Online Platforms
                    <br />
                    • Earned About 73,00,000/ Through Teaching And Other Related
                    Ventures
                    <br />
                    • On a Mission to Empower 10,000 Teachers in Next 2 Years.
                    By
                    <br />
                    Making them able to earn minimum 100,000 / month
                    <br />
                    • Building Own Android/los Applications <br />
                    • Made Two Investments .<br />
                  </MKTypography>
                </Grid>
              </Grid>

              <div style={{ marginTop: "100px" }}>
                <MKTypography variant="h1" fontWeight="medium" color="dark">
                  {headingHero4}
                </MKTypography>
                <MKTypography variant="h3" color="dark" fontWeight="medium" >
                  {descriptionHero4}
                </MKTypography>
              </div>

              <MKButton
                // variant="gradient"
                onClick={() => {

                  window.open(videoUrl2, "_blank");

                }}
                color="primary"
                size="large"
                component="a"
                style={{
                  // marginBlock: "2%",
                  fontSize: window.innerWidth < 1024 ? "15px" : "30px",
                  fontWeight: 500,
                  marginTop: "20px",
                  textTransform: "capitalize",
                }}
              >
                Enroll Yourself in the Free Webinar Now
              </MKButton>
              <Grid
                container
                spacing={3}
                alignItems="center"
                style={{ marginTop: "80px" }}
              >
                <Grid item xs={12} lg={12}>
                  <MKTypography variant="h1" fontWeight="medium" color="dark">
                    "{headingHero5}"
                  </MKTypography>
                </Grid>
              </Grid>
            </div>
            <div>

            </div>
            <Container
              style={{
                marginBlock: "60px",
              }}
            >

            </Container>
          </Grid>
        </Container>

        <ReviewSlider />
        <Container sx={{ mt: 6 }}>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            > <MKTypography
              style={{
                marginTop: "20px",
              }}
              // style={window.innerWidth >= 1024 ? largeScreenStyle : {}}
              variant="h4"
              fontWeight="medium"
              color="dark"
            >
                Limited seats available !
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <MKButton
                // variant="gradient"
                onClick={() => {
                  window.open(videoUrl2, "_blank");
                }}
                color="primary"
                size="large"
                component="a"
                style={{
                  // marginBlock: "2%",
                  fontSize: window.innerWidth < 1024 ? "15px" : "30px",
                  fontWeight: 500,
                  marginTop: "20px",
                  textTransform: "capitalize",
                }}
              >
                Get Yourself Register in this Free Webinar
              </MKButton>


            </Grid>

          </Grid>
        </Container>

        <Contact />
        <Footer />
        {/* */}
      </MKBox>
    </>
  );
}

export default DesignBlocks2;
