// @mui material components
import Icon from "@mui/material/Icon";
import PageHeadersEnroll from "layouts/sections/page-sections/page-enroll";
import PageHeadersShopify from "layouts/sections/page-sections/page-shopify";
import Presentation2 from "pages/Presentation/webinar.js";
const routes = [
  {
    name: "sections",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "page sections",
        description: "See all sections",
        dropdown: true,
        collapse: [
          {
            name: "page headers",
            route: "/course/:id",
            component: <PageHeadersShopify />,
          },
          {
            name: "page enroll",
            route: "/enroll/",
            component: <PageHeadersEnroll />,
          },
          {
            name: "page webinar",
            route: "/webinar/",
            component: <Presentation2 />,
          },
        ],
      },
    ],
  },
];

export default routes;
