
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Box, Modal } from "@mui/material";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function WhatAchieveSection({ btn, package_name, courseDetails }) {
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth >= 1024 ? 600 : "auto",
    bgcolor: "background.paper",
    border: "1px solid lightGrey",
    boxShadow: 24,
    p: 4,
  };
  const largeScreenStyle = {
    fontSize: "60px",
  };
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  return (
    <MKBox >
      <Container
        style={{
          marginInline: "auto",
          marginBlock: "5%",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12}>
            <MKTypography
              textAlign="center"
              style={{
                fontSize: window.innerWidth < 1024 ? "2rem" : "2.5rem",
                width: "100%",

              }}
              variant="h1"
              fontWeight="bold"
              color="dark"
            >
              What you can achieve after these course?
            </MKTypography>
            <MKTypography
              textAlign="center"
              // style={{ fontFamily: '"Bilbo", Sans-serif' }}
              //  style={window.innerWidth >= 1024 ? largeScreenStyle : {}}
              variant="h4"
              fontWeight="medium"
              color="dark"
            >
              Opens Multiple Income Streams
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={4}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="phone_iphone"
                    title="Online Tutoring"
                    description="You will be able to start online tutoring and earn a good amount by sitting at your home."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="school"
                    title="Establishing an Online Academy"
                    description="You will be able to establish your own online academy, a business which can make you earn a huge sum of money."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apartment"
                    title="Tutor Providing Agency "
                    description="You will be able to establish your own agency through which you can empower other teachers along with making good money.
                    "
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Multiple Income Streams"
                    description="You will have different income sources making you safe from any mishaps."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="description"
                    title={`Earn Thousands of $ Each Month`}
                    description={`You will be able to earn in dominated currencies like US Dollars by teaching international students through different freelancing platforms.`}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="reduce_capacity"
                    title="Start Physical Academy in Your City"
                    description="If you understand all these techniques and processes, you will have enough knowledge and resources to start an academy in your city. Sky is the limit!"
                  />
                </MKBox>
              </Grid>
              {btn === null || btn === undefined ? (
                <></>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <MKButton
                    onClick={() => {
                      // window.location.href = `/enroll?package_name=${package_name}`;
                      navigate('/enroll', {
                        state: {
                          package_name, courseDetails,
                          isBundle: false
                        }
                      });
                    }}
                    variant="gradient"
                    color="info"
                    size="large"
                    component="a"
                    style={{
                      marginBlock: "2%",
                      fontWeight: 500,
                      textTransform: "capitalize",
                    }}
                  >
                    Enroll Now PKR {btn}
                  </MKButton>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* <MKTypography variant="h6" color="dark" fontWeight="light">
                  1 Day No Question Asked Refund Policy
                </MKTypography> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MKTypography id="modal-modal-title" variant="h4">
            Bundle Offer
          </MKTypography>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Limited Time Offer!
          </MKTypography>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Get the complete Educator Empowerment Program bundle under TSM
            Academy for just ₹3500!
          </MKTypography>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Don't miss out on this incredible opportunity to transform your
            teaching skills and impact the lives of your students.
          </MKTypography>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Buy now and get instant access to all courses!
          </MKTypography>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Hurry! Offer valid for a limited time only! ⏰"
          </MKTypography>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Feel free to adjust as needed!
          </MKTypography>
        </Box>
      </Modal>
    </MKBox>
  );
}

export default WhatAchieveSection;
