// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import logo from "assets/images/Logo.png";
import logo1 from "assets/images/EducatLogo.png";

// Presentation page components
import whatsapIcon from "assets/images/whatsapIcon.png";
import { Fab } from "@mui/material";
import ExampleCard from "pages/Presentation/components/ExampleCard";
import { phone_number_data } from "BaseUrl/globalConst";

// Data
import colors from "assets/theme/base/colors";

import MKButton from "components/MKButton";
import { useEffect, useState } from "react";
import { get } from "BaseUrl/api";
import { Avatar, CircularProgress } from "@mui/material";
// import { video_url_home } from "BaseUrl/api";
import BundleOffer from "pages/LandingPages/AboutUs/sections/BundleOffer";
import WhatAchieveSection from "pages/LandingPages/AboutUs/sections/WhatAchieveSection";
import ReviewSlider from "pages/LandingPages/Author/sections/ReviewsSlider";
import FAQS from "pages/LandingPages/Author/sections/FAQS";
import Contact from "pages/LandingPages/Author/sections/Contact";
import Footer from "pages/LandingPages/Author/sections/Footer";
import { post } from "BaseUrl/api";
const { info } = colors;
function DesignBlocks({ setLoading }) {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [videoUrl, setVideourl] = useState("");

  const fetchProducts = async () => {
    try {
      const apiData = await get("package/getAllActivePackages"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        setLoader(false);
      } else {
        console.log(apiData.result);

        console.log("COURSSES");
        let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const fetchUrl = async () => {
    try {
      const apiData = await get("package/getHomepageVideoUrl"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        // setLoader(false);
      } else {
        console.log(apiData.videoUrl);
        const data = apiData.videoUrl;
        let newHeight;

        if (window.innerWidth <= 600) {
          // 600px is generally the breakpoint for mobile devices
          newHeight = `${window.innerWidth}px`;
        } else {
          newHeight = "520px";
        } // Set your desired height here
        const updatedVideoUrl = data.replace(
          'height="100%"',
          `height="${newHeight}"`
        );

        console.log("Video url");
        setVideourl(updatedVideoUrl);
        // let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        // setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const [headingHero1, setHeadingHero1] = useState("");
  const [descriptionHero1, setDescriptionHero1] = useState("");
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const fetchContent = async () => {
    try {
      const postData = {
        name: "Hero",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentSingleEnroll = async () => {
    try {
      const postData = {
        name: "ENROLLSINGLE",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero1(apiData.data.heading);
        setDescriptionHero1(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const callApi = async () => {
    Promise.all([fetchContent(), fetchContentSingleEnroll(), fetchProducts(), fetchUrl()])
      .then(() => {
        setLoading(false);
        // alert("All API calls are done");
      })
  }
  useEffect(() => {
    callApi()
  }, []);
  const largeScreenStyle = {
    fontSize: "80px",
  };

  return (
    <>

      <MKBox>
        <div
          style={{

            display: "flex",
            justifyContent: "space-between",
            marginInline: window.innerWidth < 1024 ? "0px" : "20px",
            marginBlock: "20px",
          }}
        >
          <Avatar
            src={logo}
            variant="square"
            style={{ width: "200px", height: "auto" }}
          />

          <Avatar
            src={logo1}
            variant="square"
            style={{ width: "200px", height: "auto" }}
          />

        </div>
        <Container>

          <Grid
            container
            item
            xs={12}
            lg={12}
            flexDirection="column"
            alignItems="center"
            sx={{
              textAlign: "center",
              // marginTop: "20px",

            }}
          >



            <MKTypography
              style={{
                width: "100%",
                fontSize: window.innerWidth < 1024 ? "40px" : "80px",
                marginBottom: "2%",
              }}
              variant="h1"
              fontWeight="bold"
              color="dark"
            >
              {headingHero}
            </MKTypography>


            <MKTypography
              variant="h3"
              fontWeight="bold"
              style={{
                backgroundColor: "orange",
                paddingBlock: window.innerWidth < 1024 ? "10px" : "20px",
                paddingInline: window.innerWidth < 1024 ? "0px" : "20px",
                fontSize: window.innerWidth < 1024 ? "20px" : "30px",
                lineHeight: "1.2",
                color: "white",
                fontFamily: '"Roboto Condensed", Sans-serif',
                borderRadius: "10px",
              }}
            >


              The First-Ever Training Center for Empowering Teachers
              Financially in Pakistan

            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              fontWeight="light"
              style={{
                width: window.innerWidth < 1024 ? "100%" : "80%",
                fontSize: window.innerWidth < 1024 ? "15px" : "24px",
                marginBlock: "3%",

              }}
            >
              {descriptionHero}
            </MKTypography>

            <Grid container justifyContent="center">
              <Grid item xs={12} md={8}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ` ${videoUrl}`,
                  }}
                  style={{
                    // height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    height: window.innerWidth < 1024 ? "300px" : 'auto',
                  }}
                />
                <MKButton

                  onClick={() => {
                    window.location.href = `/enroll/`;
                  }}
                  color="primary"
                  size="large"
                  component="a"
                  style={{
                    marginBlock: "1%",
                    fontSize: window.innerWidth < 1024 ? "15px" : "15px",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                >
                  Start Earning Today!
                </MKButton>
              </Grid>

            </Grid>
            <div
              style={{
                paddingBlock: "0%",
                marginBlock: "0%",
                marginTop: window.innerWidth < 1024 ? "-15%" : null,
              }}
            >
              <BundleOffer />
            </div>

            <Container
              style={{
                paddingBlock: "0%",
                marginBlock: "0%",
                marginTop: window.innerWidth < 1024 ? "-15%" : null,
              }}
            >
              <MKTypography
                style={{
                  marginBottom: "10px",
                  textTransform: "uppercase",
                  fontSize: window.innerWidth < 1024 ? "30px" : "40px",
                }}
                textAlign="center"
                variant="h1"
                fontWeight="bold"
                color="dark"
              >
                {headingHero1}
              </MKTypography>

              <MKTypography
                style={{
                  width:
                    window.innerWidth < 1024 ? "100%" : "80%", margin: "auto",
                  fontSize: window.innerWidth < 1024 ? "15px" : "20px",
                }}
                textAlign="center"
                variant="body1"
                color="dark"
                fontWeight="light"
              >
                {descriptionHero1}
              </MKTypography>

            </Container>
          </Grid>
        </Container>
        <Container sx={{ mt: 6 }}>

          <Grid container spacing={3} sx={{ mb: 4 }}>

            <Grid item xs={12} lg={1}></Grid>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={3}>
                {data.map((itemD, index) => {
                  return (
                    <>
                      <Grid item xs={12} md={4} sx={{ mb: 2 }} key={index}>
                        <Link to={`/course/${itemD.package_id}`}>
                          <ExampleCard
                            image={itemD.image}
                            package_name={itemD.package_name}
                            package_price={itemD.package_price}
                            offer_price={itemD.package_offer_price}
                            enrolled_students={itemD.enrolled_students}
                          />
                        </Link>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <WhatAchieveSection />
        <ReviewSlider />
        <FAQS />
        <Contact />
        <Footer />
        {/* */}
      </MKBox>
    </>
  );
}

export default DesignBlocks;
