
import { Avatar } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import logo from "assets/images/Logo.png";
import logo2 from "assets/images/Logo2.png";
import EducatLogo from "assets/images/EducatLogo.png";
import { post } from "BaseUrl/api";

import { email_tsm } from "BaseUrl/globalConst";
import { address } from "BaseUrl/globalConst";
import { phone_number_data } from "BaseUrl/globalConst";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";

function Footer() {
  const [descriptionHero1, setDescriptionHero1] = useState("");
  const [descriptionHero2, setDescriptionHero2] = useState("");
  const [descriptionHero3, setDescriptionHero3] = useState("");
  const [descriptionHero4, setDescriptionHero4] = useState("");

  const fetchContentSingleEnroll = async () => {
    try {
      const postData = {
        name: "FOOTER",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setDescriptionHero1(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentSingleEnroll1 = async () => {
    try {
      const postData = {
        name: "Number",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setDescriptionHero2(apiData.data.heading);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentSingleEnroll2 = async () => {
    try {
      const postData = {
        name: "Email",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setDescriptionHero3(apiData.data.heading);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const fetchContentSingleEnroll3 = async () => {
    try {
      const postData = {
        name: "Location",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setDescriptionHero4(apiData.data.heading);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  useEffect(() => {
    fetchContentSingleEnroll();
    fetchContentSingleEnroll1();
    fetchContentSingleEnroll2();
    fetchContentSingleEnroll3();
  }, []);
  return (
    <MKBox component="footer"
      style={{
        marginTop: window.innerWidth < 1024 ? "0px" : "0px",
        padding: window.innerWidth < 1024 ? "0px" : "50px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          flexDirection: window.innerWidth < 1024 ? "column" : "row",
          display: "flex",
          width: "90%",
          marginInline: "5%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: window.innerWidth < 1024 ? "100%" : "40%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            src={EducatLogo}
            variant="square"
            style={{
              width: "200px", height: "100px",
              objectFit: "contain"
            }}

          />
          <MKTypography
            variant="h6"
            fontWeight="light"
            mb={6}

          >
            {descriptionHero1}
          </MKTypography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: window.innerWidth < 1024 ? "100%" : "40%",
            marginBottom: window.innerWidth < 1024 ? "10%" : "0%",
          }}
        >
          <div>
            <i className="fa fa-envelope" />
            <MKTypography fontWeight="light" variant="h6" color="dark" mr={3}>
              {descriptionHero2}
            </MKTypography>
          </div>
          <div>
            <MKTypography
              fontWeight="light"
              component={Link}
              variant="h6"
              color="dark"
              mr={3}
            >
              <i className="fa fa-envelope" />
              {descriptionHero3}
            </MKTypography>
          </div>
          <div>
            <MKTypography
              fontWeight="light"
              component={Link}
              variant="h6"
              color="dark"
              mr={3}
            >
              <i className="fa fa-map-marker" />
              {descriptionHero4}
            </MKTypography>
          </div>
        </div>
      </div>

    </MKBox>
  );
}

export default Footer;
