import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
// PageHeaders page components
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";

// PageHeaders page components code
import BundleOffer from "pages/LandingPages/AboutUs/sections/BundleOffer";
import WhatAchieveSection from "pages/LandingPages/AboutUs/sections/WhatAchieveSection";
import Contact from "pages/LandingPages/Author/sections/Contact";
import FAQS from "pages/LandingPages/Author/sections/FAQS";
import ReviewSlider from "pages/LandingPages/Author/sections/ReviewsSlider";
import MKBox from "components/MKBox";
import Footer from "pages/LandingPages/Author/sections/Footer";
import { Avatar, Box, Fab, Grid, Modal } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { post } from "BaseUrl/api";
import whatsapIcon from "assets/images/whatsapIcon.png";
import { phone_number_data } from "BaseUrl/globalConst";
import logo1 from "assets/images/EducatLogo.png";
import bundleOfferImg from "assets/images/bundleOffer.png";
import celebrate from "assets/images/celebrate.png";
import close from "assets/images/close.png";

function PageHeadersShopify() {
  const date = new Date().getFullYear();
  const [feature, setFeature] = useState([]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth >= 1024 ? 600 : "90%",
    bgcolor: "background.paper",
    border: "1px solid lightGrey",
    boxShadow: 24,
    p: 4,
  };
  const [courseDetails, setCourseDetails] = useState({
    package_id: "",
    package_name: "",
    package_offer_price: "",
    package_price: "",
    total_students: "",
    image: "",
    feature: "",
    video_url: "",
  });
  const [videos, setVideos] = useState([]);
  const { id } = useParams();
  // get Course Details
  const fetchProducts = async () => {
    try {
      const postData = {
        package_id: id,
      };
      const apiData = await post("package/getByProgramId", postData); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        // setLoader(false);
      } else {
        console.log(apiData.data);
        const data = apiData?.data?.videos[0]?.video_url;
        let newHeight;

        if (window.innerWidth <= 600) {
          // 600px is generally the breakpoint for mobile devices
          newHeight = `${window.innerWidth}px`;
        } else {
          newHeight = "520px";
        } // Set your desired height here
        const updatedVideoUrl = data.replace(
          'height="100%"',
          `height="${newHeight}"`
        );

        console.log("Video url");
        setCourseDetails({
          package_id: apiData?.data?.course?.package_id,
          package_name: apiData?.data?.course?.package_name,
          package_offer_price: apiData?.data?.course?.package_offer_price,
          package_price: apiData?.data?.course?.package_price,
          total_students: apiData?.data?.course?.total_students,
          image: apiData?.data?.course?.image,
          feature: apiData?.data?.course?.feature,
          video_url: updatedVideoUrl,
        });
        setFeature(apiData?.data?.course?.feature);
        // let videoArray=apiData?.data.videos
        // Assuming apiData?.data?.videos is an array of objects with a 'name' field
        const nameArray = apiData?.data?.videos.map((video) => video.title);

        // nameArray will now be an array containing just the 'name' fields from the objects in videoArray
        console.log(nameArray);
        setVideos(nameArray);

        console.log("COURSSES");
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        // setData(arrayOfObjects);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const fetchProducts1 = async () => {
    try {
      const postData = {
        name: "PopUpModal",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const callApi = async () => {
    Promise.all([fetchProducts(), fetchProducts1()])
      .then((res) => {
        handleLoading(false);
      })
  }
  useEffect(() => {
    callApi();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [loading, setLoading] = useState(true);
  const handleLoading = (status) => {
    setLoading(status);
  };
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setShowModal(true);
    // }, 30000); // 30 seconds in milliseconds

    // return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: 'white',
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99999,
          display: loading ? "flex" : "none", // Use "flex" instead of "block"
          justifyContent: "center", // Horizontally centers content
          alignItems: "center", // Vertically centers content
          textAlign: "center",
          flexDirection: "column", // Ensures items stack vertically
        }}
      ><div>
          <Avatar
            src={logo1}
            variant="square"
            style={{
              width: "200px", height: "auto",
              tintColor: "white",
            }}
          />
          <h3
            style={{
              color: "black",
              // Optional: adds space between the Avatar and text
              fontWeight: 600,
              marginTop: "10px",
            }}
          >
            Loading ...
          </h3>
        </div>
      </div>

      <div
        style={{
          display: loading ? "none" : "block",
        }}
      >

        <MKBox component="section">
          <HeaderOne courseDetails={courseDetails} feature={feature} />
        </MKBox>
        <div style={{}}>
          <Information
            btn={courseDetails.package_offer_price}
            package_name={courseDetails.package_name}
            courseDetails={courseDetails}
          />
        </div>

        <Team videos={videos} />
        <BundleOffer />

        <WhatAchieveSection
          btn={courseDetails.package_offer_price}
          package_name={courseDetails.package_name}
          courseDetails={courseDetails}
        />

        <ReviewSlider />
        <FAQS />
        <Contact />
        <Footer />
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography
              variant="h5"
              fontWeight="medium"
              style={{ textDecoration: "underline" }}
            >
              Contact Support
            </MKTypography>
            <MKTypography variant="h5" fontWeight="regular">
              All rights reserved. Copyright &copy; {date} .
            </MKTypography>
          </Grid>
        </Grid>

      </div>
      <div
        style={{
          position: "fixed",
          bottom: 40,
          left: 10,
          margin: "16px",
          background: "white",
          border: "1px solid lightGrey",
          padding: "10px",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          color: "#fff",
          // padding: '10px 20px',
          borderRadius: "40px",
          cursor: "pointer",
          // boxShadow: "-4px 5px 35px 0px rgba(0,0,0,0.75)",
        }}
        onClick={() => {
          // Add your button click logic here
          // Construct the WhatsApp link
          const whatsappLink = `https://wa.me/${phone_number_data}`;

          // Open the WhatsApp chat in a new window or tab
          window.open(whatsappLink, "_blank");
        }}
      >
        <Fab size="medium" color="secondary" aria-label="whatsap">
          <Avatar
            style={{
              width:
                window.innerWidth >= 1024 ? "100px" :
                  "50px", height: "auto"
            }}
            variant="square"
            src={whatsapIcon}
            alt="whatsap No"
          />
        </Fab>

      </div>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <img
              src={close}
              alt="close"
              style={{
                width: "30px", height: "auto", float: "right", cursor: "pointer",
                position: "absolute", top: "10px", right: "10px"

              }}
              onClick={handleClose}
            />
          </div>
          <MKTypography id="modal-modal-title" variant="h4" style={{ display: 'flex', justifyContent: 'center' }}>
            {headingHero}
          </MKTypography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={bundleOfferImg}
              alt="bundle"
              style={{ width: "60px", height: "auto" }}
              variant="square"
            />
            <Avatar
              src={celebrate}
              alt="bundle"
              style={{ width: "60px", height: "auto" }}
              variant="square"
            />
          </div>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            {descriptionHero}
          </MKTypography>
        </Box>
      </Modal>
    </>
  );
}

export default PageHeadersShopify;
