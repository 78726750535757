/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";



const Team = ({ videos }) => {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="light"
      position="relative"
      py={6}
      px={{ xs: -10, lg: 20 }}

    >


      <Grid>
        <Grid item xs={12} lg={12}>
          <MKBox p={{
            xs: 2,
            lg: 10
          }}>
            <MKTypography style={{
              lineHeight: 1.5,
              fontSize: window.innerWidth < 1024 ? "1.5rem" : "2.5rem",
            }}
              textAlign="center"
              variant="h1"
              color="white"
              fontWeight="light"

            >
              " The future belongs to those who believe in the beauty of their dreams. Unlock the path to financial abundance and live the life you've always dreamed of. Take the leap today to revolutionize your teaching career and pave the way for limitless wealth and success."
            </MKTypography>

          </MKBox>
        </Grid>
      </Grid>

    </MKBox>
  );
}

export default Team;
