// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";
import DesignBlocks2 from "pages/Presentation/sections/DesignBlocks2";

import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import { Box, Modal, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import bundleOfferImg from "assets/images/bundleOffer.png";
import celebrate from "assets/images/celebrate.png";
import { Avatar } from "@mui/material";
import { phone_number_data } from "BaseUrl/globalConst";
import whatsapIcon from "assets/images/whatsapIcon.png";
import { post } from "BaseUrl/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth >= 1024 ? 600 : "auto",
  bgcolor: "background.paper",
  border: "1px solid lightGrey",
  boxShadow: 24,
  p: 4,
};

function Presentation2() {
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const fetchContent = async () => {
    try {
      const postData = {
        name: "PopUpModal",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    fetchContent();
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 30000); // 30 seconds in milliseconds

    return () => clearTimeout(timer);
  }, []);
  const date = new Date().getFullYear();
  return (
    <>
      <Card
        sx={{
          // p: 2,
          // mx: { xs: 2, lg: 3 },
          mt: -8,
          // mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <DesignBlocks2 />
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
          <MKTypography
            variant="h5"
            fontWeight="medium"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Contact Support
          </MKTypography>
          <MKTypography variant="h5" fontWeight="regular">
            All rights reserved. Copyright &copy; {date} .
          </MKTypography>
        </Grid>
      </Grid>
      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          margin: "16px",
          background: "white",
          border: "1px solid lightGrey",
          padding: "10px",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          color: "#fff",
          // padding: '10px 20px',
          borderRadius: "40px",
          cursor: "pointer",
          // boxShadow: "-4px 5px 35px 0px rgba(0,0,0,0.75)",
        }}
        onClick={() => {
          // Add your button click logic here
          // Construct the WhatsApp link
          const whatsappLink = `https://wa.me/${phone_number_data}`;

          // Open the WhatsApp chat in a new window or tab
          window.open(whatsappLink, "_blank");
        }}
      >
        <Fab size="medium" color="secondary" aria-label="whatsap">
          <Avatar
            style={{ width: "100px", height: "100px" }}
            variant="square"
            src={whatsapIcon}
            alt="whatsap No"
          />
        </Fab>
        <p style={{ color: "#000", marginLeft: "20px", fontWeight: 600 }}>
          Contact Us
        </p>
      </div>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MKTypography id="modal-modal-title" variant="h4" style={{ display: 'flex', justifyContent: 'center' }}>
            {headingHero}
          </MKTypography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={bundleOfferImg}
              alt="bundle"
              style={{ width: "60px", height: "auto" }}
              variant="square"
            />
            <Avatar
              src={celebrate}
              alt="bundle"
              style={{ width: "60px", height: "auto" }}
              variant="square"
            />
          </div>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            {descriptionHero}
          </MKTypography>
        </Box>
      </Modal>
    </>
  );
}

export default Presentation2;
