/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import { useEffect, useState } from "react";
import { post } from "BaseUrl/api";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { phone_number_data } from "BaseUrl/globalConst";

function Contact() {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMesage] = useState("");
  const [FormFillEror, setFormFillError] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleFormSubmit = async () => {
    if (
      fullName === "" ||
      phoneNumber === "" ||
      email === "" ||
      subject === "" ||
      message === ""
    ) {
      setFormFillError(true);
    } else {
      setLoading(true);
      setFormFillError(false);
      const postData = {
        name: fullName,
        phone_number: phoneNumber,
        email: email,
        subject: subject,
        message: message,
      };
      try {
        // craete product
        const apiData = await post("contact_us/createContact", postData); // Specify the endpoint you want to call
        console.log("ADD Review ");

        console.log(apiData);

        setTimeout(() => {
          // After a delay (for demonstration), reset loading state to false
          setLoading(false);

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your Message is submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          setFullName("");
          setPhoneNumber("");
          setEmail("");
          setSubject("");
          setMesage("");
          // Add your form submission logic here
        }, 1000);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showCancelButton: false, // Remove the OK button
        });
      }
    }
  };
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const [headingHero2, setHeadingHero2] = useState("");
  const [headingHero3, setHeadingHero3] = useState("");
  const fetchContent = async () => {
    try {
      const postData = {
        name: "Contact Us",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) {}
  };
  const fetchContentEmail = async () => {
    try {
      const postData = {
        name: "Email",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero2(apiData.data.heading);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) {}
  };
  const fetchContentPhone = async () => {
    try {
      const postData = {
        name: "Number",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero3(apiData.data.heading);
     
      }
    } catch (error) {}
  };
  useEffect(() => {

    fetchContent();
    fetchContentEmail();
    fetchContentPhone();



  }, []);
  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      {headingHero}
                    </MKTypography>
                    <MKTypography
                      variant="body2"
                      color="white"
                      opacity={0.8}
                      mb={3}
                    >
                    {descriptionHero}
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {headingHero3}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {headingHero2}
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Have Questions? Contact us
                    </MKTypography>
                    {FormFillEror ? (
                      <MKTypography
                        fontWeight="medium"
                        style={{ color: "red" }}
                        id="modal-modal-title"
                        variant="h6"
                      >
                        Please Fill All Fields!
                      </MKTypography>
                    ) : null}
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} md={6} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          label="Enter Full Name "
                          placeholder="John Doe"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          type="number"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          label="Enter Phone Number"
                          placeholder="03-------"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          label="Enter Email Address"
                          placeholder="john@gmail.com"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          label="Enter Subject"
                          placeholder="Question"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          value={message}
                          onChange={(e) => setMesage(e.target.value)}
                          label="Enter Message"
                          placeholder="I want to say that..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton
                        onClick={handleFormSubmit}
                        variant="gradient"
                        color="info"
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Send Message"
                        )}
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
