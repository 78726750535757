import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import { Box, CircularProgress, Modal, Rating, Skeleton } from "@mui/material";
import { useState } from "react";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { post } from "BaseUrl/api";
import Swal from "sweetalert2";

// Images
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth >= 1024 ? 400 : "auto",
  bgcolor: "background.paper",
  border: "1px solid lightGrey",
  boxShadow: 24,
  p: 4,
};
const HeaderOne = ({ courseDetails, feature }) => {
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [review, setReview] = useState("");
  const [FormFillEror, setFormFillError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ratingCount, setRatingCount] = useState(0);
  const handleClose = () => setShowModal(false);
  const handleSubmitForm = async () => {
    if (fullName === "" || review === "") {
      setFormFillError(true);
    } else {
      setLoading(true);
      setFormFillError(false);

      const postData = {
        name: fullName,
        review: review,
        rating: ratingCount,
      };
      try {
        // craete product
        const apiData = await post("review/createReview", postData); // Specify the endpoint you want to call
        console.log("ADD Review ");

        console.log(apiData);
        setTimeout(() => {
          // After a delay (for demonstration), reset loading state to false
          setLoading(false);

          setShowModal(false);

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your Review is submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          setFullName("");
          setReview("");
          setRatingCount(0);
        }, 1000);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    }
  };
  return (

    <div
      style={{
        display: "flex",
        flexDirection: window.innerWidth < 1024 ? "column" : "row",
        justifyContent: "space-between",
        backgroundColor: "#00092A",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: window.innerWidth < 1024 ? "100%" :
            "50%",
          padding: window.innerWidth < 1024 ? "10px" : "50px",
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          fontWeight="medium"
          mb={3}
          style={{
            fontSize: window.innerWidth < 1024 ? "35px" : "40px",
            marginTop: window.innerWidth < 1024 ? "50px" : "0px",
            width: window.innerWidth < 1024 ? "100%" : "80%",
          }}
        >
          {courseDetails?.package_name}
        </MKTypography>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <MKTypography
            variant="body1"
            color="white"
            style={{ color: "orange", fontSize: "16px", marginRight: "10px" }}
          >
            5 Star Ratings
          </MKTypography>
          <StarIcon style={{ color: "orange", marginLeft: "10px" }} />
          <StarIcon style={{ color: "orange" }} />
          <StarIcon style={{ color: "orange" }} />
          <StarIcon style={{ color: "orange" }} />
          <StarIcon style={{ color: "orange" }} />
        </div>
        <MKTypography
          variant="body2"
          color="white"
          style={{
            marginLeft: "5px",
            fontSize: "18px",
            // justify text center
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          {feature}
        </MKTypography>
      </div>
      <div
        style={{
          width: window.innerWidth < 1024 ? "100%" : "50%",
        }}
      >
        {courseDetails.video_url === null ||
          courseDetails.video_url === undefined ||
          courseDetails.video_url === "" ? (
          <>
            <Skeleton variant="rectangular" height={500} width="100%" />
          </>
        ) : (
          <div
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // width: "100%",
              // borderRadius: "10px",
              marginTop: "50px",
            }}
            dangerouslySetInnerHTML={{
              __html: ` ${courseDetails.video_url}`,
            }}
          />
        )}
      </div>
    </div>

  );
};

export default HeaderOne;
