/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useNavigate } from 'react-router-dom';



function Information({ btn, package_name, courseDetails }) {
  const navigate = useNavigate();
  return (
    <MKBox component="section" py={12}>
      <Container>
        {/* dssddssd  {btn}What you can achieve after these course? */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="lock_open"
                    title="Course Access"
                    description="Access to all videos of the course "
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="update"
                    title="Updates"
                    description="Regular content updates as per need "
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="info"
                    title=" Practical Knowledge "
                    description="Every step has been practically performed in the course videos"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="help"
                    title="Support "
                    description="Get proper support at every step"
                  />
                </MKBox>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <MKButton
                  onClick={() => {
                    // window.location.href = `/enroll?package_name=${package_name}&courseDetails=${courseDetails}`;
                    navigate('/enroll', { state: { package_name, courseDetails } });
                  }}
                  variant="gradient"
                  color="info"
                  size="large"
                  component="a"
                  style={{
                    marginBlock: "2%",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                >
                  Enroll Now PKRs {btn}
                </MKButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* <MKTypography variant="h6" color="dark" fontWeight="light">
                  1 Day No Question Asked Refund Policy
                </MKTypography> */}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
