import React, { useState } from "react";
import {
  Grid,
  Rating,
  Typography,
  Card,
  CardContent,
  Icon,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import Avatar from "assets/theme/components/avatar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "BaseUrl/api";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const ReviewSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Icon
        className={className}
        style={{
          ...style,
          display: "block",
          color: "black",
          fontSize: "200px",
        }}
        onClick={onClick}
      >
        arrow_forward
      </Icon>
      // <ArrowForwardIosIcon
      //   className={className}
      //   style={{ ...style, display: 'block', color: 'black' }}
      //   onClick={onClick}
      // />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Icon
        className={className}
        style={{ ...style, display: "block", color: "black" }}
        onClick={onClick}
      >
        arrow_back
      </Icon>
      // <ArrowBackIosIcon
      //   className={className}
      //   style={{ ...style, display: 'block', color: 'black' }}
      //   onClick={onClick}
      // />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //   const settings = {
  //     dots: true,
  //     infinite: true,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     className: "center",
  //     centerMode: true,
  //     centerPadding: "60px",
  //     autoplay: true,
  //     speed: 500,
  //     responsive: [
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1,
  //           centerMode: true,
  //           centerPadding: "0",
  //         },
  //       },
  //     ],
  //     beforeChange: (oldIndex, newIndex) => {
  //       setCurrentSlide(newIndex);
  //     },
  //   };
  const [sliderSArray, setSliderSArray] = useState([]);
  const [loader, setLoader] = useState(true);
  const fetchProducts = async () => {
    try {
      const apiData = await get("review/getAllReviews"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        // setLoader(false)
      } else {
        console.log(apiData.result);

        console.log("Reviews");

        setSliderSArray(apiData.result);
        // setLoader(false)
      }
    } catch (error) {
      setLoader(false);
    }
  };

  React.useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <>
      {/* {
      // if its small screen then not show anything 
      window.innerWidth < 1024 ? null : (  */}
      <MKBox
        component="section"
        variant="gradient"
        bgColor="light"
        position="relative"
        py={6}
        px={{ xs: 2, lg: 0 }}
        mx={-2}
      >
        <Container>
          <div style={{ paddingBlock: "20px" }}>
            <MKTypography
              textAlign="center"
              style={{
                paddingInline: window.innerWidth < 1024 ? "0px" : "20px",
                marginBottom: '20px',
                fontSize: window.innerWidth < 1024 ? "2rem" : "2.5rem",
              }}
              //  style={ ? largeScreenStyle : {}}
              variant="h1"
              fontWeight="medium"
              color="light"
            >
              {/* add \n */}
              See What Teachers Are Saying About Educators Empowerment Programme

            </MKTypography>
            <div className="slider-container" style={{ paddingBlock: "1%" }}>
              <Slider {...settings}>
                {sliderSArray.map((item, index) => (
                  <div style={{ padding: "100px" }}>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        backgroundColor: "white",
                        padding: '10px',
                        margin: '10px',
                        borderRadius: '10px'
                      }}
                    >
                      <MKTypography
                        textAlign="center"
                        variant="h5"
                        fontWeight="bold"
                        color="dark"
                      >
                        {item.name}
                      </MKTypography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Rating
                          name="size-large"
                          value={item.rating}
                          size="large"
                        />
                      </div>
                      <MKTypography
                        textAlign="center"
                        style={{
                          // marginBottom: "20px",
                          width: "100%",
                          alignSelf: "center",
                          padding: "10px",
                          fontSize: window.innerWidth < 1024 ? "1rem" : "1.2rem",
                        }}
                        variant="h4"
                        fontWeight="medium"
                        color="dark"
                      >
                        {item.review}
                      </MKTypography>
                    </div>
                    {/* </Grid>
                      </Grid> */}
                    {/* </CardContent> */}
                    {/* </Card> */}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Container>
      </MKBox>
      {/* //   )
    // } */}


    </>);
};

export default ReviewSlider;
