/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import colors from "assets/theme/base/colors";

import { useEffect, useState } from "react";
import { get } from "BaseUrl/api";
const { info } = colors;
function WebsitePageBundle() {
  // const renderData = data.map(({ title, description, items }) => (
  //   <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
  //     {/* <Grid item xs={12} lg={3}>
  //       <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
  //         <MKTypography variant="h3" fontWeight="bold" mb={1}>
  //           {title}
  //         </MKTypography>
  //         <MKTypography
  //           variant="body2"
  //           fontWeight="regular"
  //           color="secondary"
  //           mb={1}
  //           pr={2}
  //         >
  //           {description}
  //         </MKTypography>
  //       </MKBox>
  //     </Grid> */}
  //       <Grid item xs={12} lg={1}>

  //       </Grid>
  //     <Grid item xs={12} lg={10}>
  //       <Grid container spacing={3}>
  //         {items.map(({ image, name, count, route, pro }) => (
  //           <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
  //             <Link to={pro ? "/" : route}>
  //               <ExampleCard
  //                 image={image}
  //                 name={name}
  //                 count={count}
  //                 // pro={pro}
  //               />
  //             </Link>
  //           </Grid>
  //         ))}
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // ));
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const fetchProducts = async () => {
    try {
      const apiData = await get("package/getAllActivePackages"); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        setLoader(false);
      } else {
        console.log(apiData.result);

        console.log("COURSSES");
        let arrayOfObjects = apiData.result;
        // let firstThreeObjects = arrayOfObjects.slice(0, 3);
        setData(arrayOfObjects);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <MKBox component="section" my={1} py={1}>
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={3} sx={{ mb: 10 }}>
          {/* <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography
            variant="body2"
            fontWeight="regular"
            color="secondary"
            mb={1}
            pr={2}
          >
            {description}
          </MKTypography>
        </MKBox>
      </Grid> */}
          <Grid item xs={12} lg={1}></Grid>
          <Grid item xs={12} lg={10}>
            <Grid container spacing={3}>
              {data.map((itemD, index) => {
                return (
                  <>
                    <Grid item xs={12} md={4} sx={{ mb: 2 }} key={index}>
                      <div
                        onClick={() => {
                          window.location.href = `/course/${itemD.package_id}`;
                        }}
                      >
                        <ExampleCard
                          enrollBtn={false}
                          image={itemD.image}
                          package_name={itemD.package_name}
                          package_price={itemD.package_price}
                          offer_price={itemD.package_offer_price}
                          enrolled_students={itemD.enrolled_students}
                        />
                      </div>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WebsitePageBundle;
