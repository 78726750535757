// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";

import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import { Box, Modal, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import bundleOfferImg from "assets/images/bundleOffer.png";
import celebrate from "assets/images/celebrate.png";
import { Avatar } from "@mui/material";
import { phone_number_data } from "BaseUrl/globalConst";
import whatsapIcon from "assets/images/whatsapIcon.png";
import { post } from "BaseUrl/api";
import logo1 from "assets/images/EducatLogo.png";
import close from "assets/images/close.png";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth >= 1024 ? 600 : "90%",
  bgcolor: "background.paper",
  border: "1px solid lightGrey",
  boxShadow: 24,
  p: 4,
};

function Presentation() {
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchContent = async () => {
    try {
      const postData = {
        name: "PopUpModal",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleLoading = (status) => {
    setLoading(status);
  };
  useEffect(() => {
    fetchContent();
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 30000); // 30 seconds in milliseconds

    return () => clearTimeout(timer);
  }, []);
  const date = new Date().getFullYear();
  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: 'white',
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99999,
          display: loading ? "flex" : "none", // Use "flex" instead of "block"
          justifyContent: "center", // Horizontally centers content
          alignItems: "center", // Vertically centers content
          textAlign: "center",
          flexDirection: "column", // Ensures items stack vertically
        }}
      ><div>
          <Avatar
            src={logo1}
            variant="square"
            style={{
              width: "200px", height: "auto",
              tintColor: "white",
            }}
          />
          <h3
            style={{
              color: "black",
              // Optional: adds space between the Avatar and text
              fontWeight: 600,
              marginTop: "10px",
            }}
          >
            Loading ...
          </h3>
        </div>
      </div>

      <div
        style={{
          display: loading ? "none" : "block",
        }}
      >
        <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <img
                src={close}
                alt="close"
                style={{
                  width: "30px", height: "auto", float: "right", cursor: "pointer",
                  position: "absolute", top: "10px", right: "10px"

                }}
                onClick={handleClose}
              />
            </div>
            <MKTypography id="modal-modal-title" variant="h4" style={{ display: 'flex', justifyContent: 'center' }}>
              {headingHero}
            </MKTypography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                src={bundleOfferImg}
                alt="bundle"
                style={{ width: "60px", height: "auto" }}
                variant="square"
              />
              <Avatar
                src={celebrate}
                alt="bundle"
                style={{ width: "60px", height: "auto" }}
                variant="square"
              />
            </div>
            <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
              {descriptionHero}
            </MKTypography>
          </Box>
        </Modal>
        <Card

        >

          <DesignBlocks setLoading={handleLoading} />
          {/* <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center",  }}>
                 <DesignBlocks />
          </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography
              variant="h5"
              fontWeight="medium"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Contact Support
            </MKTypography>
            <MKTypography variant="h5" fontWeight="regular">
              All rights reserved. Copyright &copy; {date} .
            </MKTypography>
          </Grid>
        </Grid> */}
        </Card>
        <Grid container spacing={3}>

          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography
              variant="h5"
              fontWeight="medium"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Contact Support
            </MKTypography>
            <MKTypography variant="h5" fontWeight="regular">
              All rights reserved. Copyright &copy; {date} .
            </MKTypography>
          </Grid>
        </Grid>
        <div
          style={{
            position: "fixed",
            bottom: 40,
            left: 10,
            margin: "16px",
            background: "white",
            border: "1px solid lightGrey",
            padding: "10px",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            color: "#fff",
            // padding: '10px 20px',
            borderRadius: "40px",
            cursor: "pointer",
            // boxShadow: "-4px 5px 35px 0px rgba(0,0,0,0.75)",
          }}
          onClick={() => {
            // Add your button click logic here
            // Construct the WhatsApp link
            const whatsappLink = `https://wa.me/${phone_number_data}`;

            // Open the WhatsApp chat in a new window or tab
            window.open(whatsappLink, "_blank");
          }}
        >
          <Fab size="medium" color="secondary" aria-label="whatsap">
            <Avatar
              style={{
                width:
                  window.innerWidth >= 1024 ? "100px" :
                    "50px", height: "auto"
              }}
              variant="square"
              src={whatsapIcon}
              alt="whatsap No"
            />
          </Fab>

        </div>

      </div>
    </>
  );
}

export default Presentation;
