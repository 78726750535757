import MKBox from "components/MKBox";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Fab,
  Grid,
  Icon,
  Modal,
} from "@mui/material";
import bundleOfferImg from "assets/images/bundleOffer.png";
import celebrate from "assets/images/celebrate.png";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { post } from "BaseUrl/api";
import whatsapIcon from "assets/images/whatsapIcon.png";
import { phone_number_data } from "BaseUrl/globalConst";
import logo from "assets/images/Logo.png";
import easyJazz from "assets/images/easyJazz.png";


import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Swal from "sweetalert2";
import { postFormData } from "BaseUrl/api";

function PageHeadersEnroll() {
  const date = new Date().getFullYear();
  const [feature, setFeature] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth >= 1024 ? 600 : "90%",
    bgcolor: "background.paper",
    border: "1px solid lightGrey",
    boxShadow: 24,
    p: 4,
  };
  // const [courseDetails, setCourseDetails] = useState({
  //   package_id: "",
  //   package_name: "",
  //   package_offer_price: "",
  //   package_price: "",
  //   total_students: "",
  //   image: "",
  //   feature: "",
  //   video_url: "",
  // });
  const [videos, setVideos] = useState([]);
  const { id } = useParams();
  // get Course Details
  // const fetchProducts = async () => {
  //   try {
  //     const postData = {
  //       package_id: id,
  //     };
  //     const apiData = await post("package/getByProgramId", postData); // Specify the endpoint you want to call
  //     console.log(apiData);
  //     if (apiData.error) {
  //       console.log("error true");
  //       // setLoader(false);
  //     } else {
  //       console.log(apiData.data);
  //       setCourseDetails({
  //         package_id: apiData?.data?.course?.package_id,
  //         package_name: apiData?.data?.course?.package_name,
  //         package_offer_price: apiData?.data?.course?.package_offer_price,
  //         package_price: apiData?.data?.course?.package_price,
  //         total_students: apiData?.data?.course?.total_students,
  //         image: apiData?.data?.course?.image,
  //         feature: apiData?.data?.course?.feature,
  //         video_url: apiData?.data?.videos[0]?.video_url,
  //       });
  //       setFeature(apiData?.data?.course?.feature);
  //       // let videoArray=apiData?.data.videos
  //       // Assuming apiData?.data?.videos is an array of objects with a 'name' field
  //       const nameArray = apiData?.data?.videos.map((video) => video.title);

  //       // nameArray will now be an array containing just the 'name' fields from the objects in videoArray
  //       console.log(nameArray);
  //       setVideos(nameArray);

  //       console.log("COURSSES");
  //       // let firstThreeObjects = arrayOfObjects.slice(0, 3);
  //       // setData(arrayOfObjects);
  //       // setLoader(false);
  //     }
  //   } catch (error) {
  //     // setLoader(false);
  //   }
  // };
  useEffect(() => {
    // fetchProducts();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowModal(true);
  //   }, 30000); // 30 seconds in milliseconds

  //   return () => clearTimeout(timer);
  // }, []);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [paymentScreenshotFile, setPaymentScreenshotFile] = useState(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorData, setErrorData] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async () => {
    if (!fullName || !email || !whatsappNumber || !paymentScreenshot) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all fields!",
        showCancelButton: false, // Remove the OK button
      });
      return;
    }
    // check if email is valid
    else if (
      !email.match(
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid email address!",
        showCancelButton: false, // Remove the OK button
      });
    }

    else {
      console.log("Full Name:", fullName);
      console.log("Email:", email);
      console.log("Whatsapp Number:", whatsappNumber);
      console.log("Payment Screenshot:", paymentScreenshot);
      setLoading(true);
      setErrorData(false);
      const postDataImage = {
        image: paymentScreenshotFile,
      };
      const responseUrl = await postFormData(postDataImage);
      console.log(responseUrl.path);
      let PathImage = responseUrl.path;
      if (PathImage === null || PathImage === undefined) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showCancelButton: false, // Remove the OK button
        });
        setLoading(false);
      } else {
        const postData = {
          full_name: fullName,
          whatsapp_number: whatsappNumber,
          email: email,
          screenshot: PathImage,
        };
        try {
          // craete product
          const apiData = await post("user/paymentRequestCreate", postData); // Specify the endpoint you want to call
          console.log("ADD Review ");

          console.log(apiData);

          setTimeout(() => {
            // After a delay (for demonstration), reset loading state to false
            setLoading(false);

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your Message is submitted! We will get back to you soon.",
              showConfirmButton: false,
              timer: 2500,
            });
            setFullName("");
            setWhatsappNumber("");
            setEmail("");
            setPaymentScreenshot(null);
            setPaymentScreenshotFile(null);
            // Add your form submission logic here
          }, 1000);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showCancelButton: false, // Remove the OK button
          });
        }
      }
    }
    // paymentRequestCreate

    // Add additional validation for email format if needed

    // Call function to proceed
    // proceedFunction({ fullName, email, whatsappNumber, paymentScreenshot });

    // Console all values
  };

  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setPaymentScreenshotFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setPaymentScreenshot(e.target.result);
        setIsButtonDisabled(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showPac, setShowPac] = useState(false)
  const [headingHero, setHeadingHero] = useState("");
  const [descriptionHero, setDescriptionHero] = useState("");

  const fetchContent = async () => {
    try {
      const postData = {
        name: "PopUpModal",
      };

      const apiData = await post("review/getWebsiteContentByName", postData); // Specify the endpoint you want to call
      console.log("GET REVIEW ");

      console.log(apiData);

      if (apiData.error) {
      } else {
        setHeadingHero(apiData.data.heading);
        setDescriptionHero(apiData.data.description);
        // setName(apiData.data.heading)
        // setReview(apiData.data.description)
        // setValue(apiData.data.name)
        // setImageUrlData(apiData.data.image)
      }
    } catch (error) { }
  };
  const location = useLocation();
  const { package_name, courseDetails, isBundle } = location.state || {};
  useEffect(() => {
    fetchContent();
    const query = new URLSearchParams(location.search);
    const packageName = query.get('package_name');

    console.log(packageName);
    console.log('courseDetails', courseDetails);
    if (packageName === null || packageName === undefined) {
      setShowPac(false)
    } else {

      setShowPac(true)
    }


    // Now you can use packageName in your useEffect
  }, []);
  return (
    <>
      <div
        style={{

          display: "flex",
          justifyContent: "flex-start",
          marginInline: window.innerWidth < 1024 ? "0px" : "20px",
          marginBlock: "20px",
          alignItems: "center",
          marginInline: "20px",
        }}
      >
        <Icon sx={{ fontWeight: "bold", cursor: 'pointer' }}
          onClick={() => {
            // goBack();
            window.history.back();
          }}
        >arrow_back</Icon>
        <Avatar
          src={logo}
          variant="square"
          style={{
            width: "200px", height: "auto",
            marginLeft: "20px"
          }}
        />

      </div>





      <Grid container>
        <Grid item xs={12} md={1}></Grid>
        {showPac ?
          <Grid item xs={12} md={4} style={{ padding: "4%" }}>
            <MKTypography
              // style={window.innerWidth >= 1024 ? largeScreenStyle : {}}
              fontWeight="bold"
              style={{ marginBottom: "30px" }}
              variant="h4"
              color="dark"
            >
              Purchase Summarys
            </MKTypography>

            <MKTypography
              textAlign="left"
              display="block"
              fontWeight="light"
              variant="h6"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "30px",
                borderBottom: "1px solid LightGrey",
              }}
              // color={color}
              textGradient
            >
              <Icon>check_circle</Icon>
              <span style={{ marginLeft: "10px" }}>
                {package_name}
              </span>
            </MKTypography>
            <MKTypography
              textAlign="left"
              display="block"
              fontWeight="light"
              variant="h6"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "30px",
                borderBottom: "1px solid LightGrey",
              }}
              // color={color}
              textGradient
            >
              <Icon>check_circle</Icon>
              <span style={{ marginLeft: "10px" }}>
                Free access to updated content
              </span>
            </MKTypography>
            <MKTypography
              textAlign="left"
              display="block"
              fontWeight="light"
              variant="h6"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "30px",
              }}
              // color={color}
              textGradient
            >
              <Icon>check_circle</Icon>
              <span style={{ marginLeft: "10px" }}>
                Certificate signed by Instructor
              </span>
            </MKTypography>



          </Grid>
          : <Grid item xs={12} md={3}
            style={{
              padding: "4%",
              backgroundColor: "white",
              borderRadius: "10px",
              marginInline: "20px",
            }}>
            <MKTypography
              // style={window.innerWidth >= 1024 ? largeScreenStyle : {}}
              fontWeight="bold"
              style={{ marginBottom: "30px" }}
              variant="h4"
              color="dark"
            >
              Purchase Summary
            </MKTypography>
            {
              courseDetails?.video_url && <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ` ${courseDetails?.video_url}`,
                  }}
                  style={{
                    width: "100%",
                    height: "200px",
                    overflow: "hidden",
                  }}
                />
              </>
            }
            <h5
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginBottom: "30px",
                borderBottom: "1px solid LightGrey",

              }}
            >
              {package_name}
              {
                courseDetails?.package_price && <span style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  display: window.innerWidth < 1024 ? "block" : "none",
                }}>
                  |  {courseDetails?.package_price}
                </span>
              }
              {
                courseDetails?.package_price && <span style={{
                  marginLeft: "10px",
                  display: window.innerWidth < 1024 ? "none" : "block",
                }}>
                  | Price  {courseDetails?.package_price}
                </span>
              }
            </h5>
            <div
              style={{
                display: isBundle === true ? "block" : "none",
              }}
            >
              <MKTypography
                textAlign="left"
                display="block"
                fontWeight="light"
                variant="h6"
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "30px",
                  borderBottom: "1px solid LightGrey",
                }}
                // color={color}
                textGradient
              >
                <Icon>check_circle</Icon>
                <span style={{ marginLeft: "10px" }}>
                  Today's Bundle Offer (All Courses)
                </span>
              </MKTypography>
              <MKTypography
                textAlign="left"
                display="block"
                fontWeight="light"
                variant="h6"
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "30px",
                  borderBottom: "1px solid LightGrey",
                }}
                // color={color}
                textGradient
              >
                <Icon>check_circle</Icon>
                <span style={{ marginLeft: "10px" }}>
                  Free access to updated content
                </span>
              </MKTypography>
              <MKTypography
                textAlign="left"
                display="block"
                fontWeight="light"
                variant="h6"
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
                // color={color}
                textGradient
              >
                <Icon>check_circle</Icon>
                <span style={{ marginLeft: "10px" }}>
                  Certificate signed by Instructor
                </span>
              </MKTypography>

              <MKTypography
                textAlign="left"
                display="block"
                fontWeight="light"
                variant="h6"
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
                textGradient
              >
                <Icon>bolt</Icon>
                <span style={{ marginLeft: "10px" }}>
                  Access to All Courses + Unlimited Support
                </span>
              </MKTypography>
            </div>
          </Grid>
        }
        <Grid item xs={12} md={3} style={{
          padding: "3%",
          backgroundColor: "white",
          borderRadius: "10px",
          marginTop: window.innerWidth < 1024 ? "20px" : "0px",
          marginInline: "20px",
        }}>
          <MKTypography fontWeight="light" variant="h5" color="dark">
            Complete all fields for successful enrollment.
          </MKTypography>
          {errorData ? (
            <MKTypography
              fontWeight="light"
              style={{ marginBottom: "20px" }}
              variant="h6"
              color="error"
            >
              Fill All Fields to continue!
            </MKTypography>
          ) : null}

          <MKInput
            variant="standard"
            style={{ marginBlock: "20px" }}
            label="Full Name"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <MKInput
            variant="standard"
            style={{ marginBottom: "20px" }}
            label="Email"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <MKInput
            variant="standard"
            label="Whatsapp Number"
            type="number"
            style={{ marginBottom: "10px" }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={whatsappNumber}
            onChange={(e) => setWhatsappNumber(e.target.value)}
          />
          <Box
            style={{
              width: "100%",
              marginInline: "2%",
              padding: "15px",
              backgroundColor: "#f2f0f0",
              border: "3px dotted #cecece",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            {paymentScreenshot === null ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBlock: "10%",
                }}
                onClick={() =>
                  document.querySelector('input[type="file"]').click()
                }
              >
                <MKTypography variant="h6">
                  Upload Payment Screenshot
                </MKTypography>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  src={paymentScreenshot}
                  variant="square"
                  style={{
                    width: "100px",
                    height: "auto",
                    marginBottom: "20px",
                  }}
                />{" "}
              </div>
            )}
          </Box>
          <div style={{
            display: "flex", justifyContent: "center",

          }}>
            <MKButton
              variant="gradient"
              color="info"
              size="large"
              component="a"
              style={{
                marginBlock: "4%",
                // marginInline: '10%',
                display: "flex",
                justifyContent: "center",
                width: "70%",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Proceed"
              )}
            </MKButton>
          </div>
        </Grid>
        <Grid item xs={12} md={3}

          style={{
            padding: "3%",
            backgroundColor: "white",
            borderRadius: "10px",
            marginTop: window.innerWidth < 1024 ? "20px" : "0px",
            marginInline: "20px",
          }}
        >
          <Avatar
            src={easyJazz}
            variant="square"
            style={{
              width: "100%",
              height: "auto",
              marginBottom: "10px",
              padding: "20px",

            }}
          />
        </Grid>

      </Grid>



      <div
        style={{
          position: "fixed",
          bottom: 40,
          left: 10,
          margin: "16px",
          background: "white",
          border: "1px solid lightGrey",
          padding: "10px",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          color: "#fff",
          // padding: '10px 20px',
          borderRadius: "40px",
          cursor: "pointer",
          // boxShadow: "-4px 5px 35px 0px rgba(0,0,0,0.75)",
        }}
        onClick={() => {
          // Add your button click logic here
          // Construct the WhatsApp link
          const whatsappLink = `https://wa.me/${phone_number_data}`;

          // Open the WhatsApp chat in a new window or tab
          window.open(whatsappLink, "_blank");
        }}
      >
        <Fab size="medium" color="secondary" aria-label="whatsap">
          <Avatar
            style={{
              width:
                window.innerWidth >= 1024 ? "100px" :
                  "50px", height: "auto"
            }}
            variant="square"
            src={whatsapIcon}
            alt="whatsap No"
          />
        </Fab>

      </div>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MKTypography id="modal-modal-title" variant="h4" style={{ display: 'flex', justifyContent: 'center' }}>
            {headingHero}
          </MKTypography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={bundleOfferImg}
              alt="bundle"
              style={{ width: "60px", height: "auto" }}
              variant="square"
            />
            <Avatar
              src={celebrate}
              alt="bundle"
              style={{ width: "60px", height: "auto" }}
              variant="square"
            />
          </div>
          <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
            {descriptionHero}
          </MKTypography>

        </Box>
      </Modal>
    </>
  );
}

export default PageHeadersEnroll;
